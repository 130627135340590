@import url(https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;700&display=swap);
@font-face {
    font-family: 'Muller Narrow';
    font-display: swap;
    src: url('https://static-resources.nickel.eu/fonts/MullerNarrow-Light.woff2') format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: 'Muller Narrow';
    font-display: swap;
    src: url('https://static-resources.nickel.eu/fonts/MullerNarrow-Medium.woff2') format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: 'Muller Narrow';
    font-display: swap;
    src: url('https://static-resources.nickel.eu/fonts/MullerNarrow-Bold.woff2') format('woff2');
    font-weight: 700;
}

@font-face {
    font-family: 'Muller Narrow';
    font-display: swap;
    src: url('https://static-resources.nickel.eu/fonts/MullerNarrow-ExtraBold.woff2') format('woff2');
    font-weight: 800;
}
